<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 80px">设备Imei：</td>
                            <td>
                                <el-input v-model="searchItem.imei" size="small" placeholder="请输入设备imei"></el-input>
                            </td>
                            <td style="width: 80px">合同编号：</td>
                            <td>
                                <el-input v-model="searchItem.contractNum" size="small" placeholder="请输入合同号"></el-input>
                            </td>
                            <td style="width: 80px">公司名称：</td>
                            <td>
                                <el-input v-model="searchItem.companyName" size="small"
                                          placeholder="请输入公司名称"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 80px;padding-top: 10px">项目名称：</td>
                            <td style="padding-top: 10px">
                                <el-input
                                        v-model="searchItem.projectName"
                                        size="small"
                                        placeholder="请输入项目名称"
                                ></el-input>
                            </td>
                            <td style="width: 80px;padding-top: 10px">设备类型：</td>
                            <td style="padding-top: 10px">
                                <el-select style="width: 100%" size="small" v-model="searchItem.deviceType"
                                           placeholder="请选择设备类型">
                                    <el-option key="0" label="全部" :value="0"></el-option>
                                    <el-option key="1" label="塔机" :value="1"></el-option>
                                    <el-option key="2" label="升降机" :value="2"></el-option>
                                    <el-option key="3" label="单盲吊" :value="3"></el-option>
                                </el-select>
                            </td>
                            <td></td>
                            <td style="text-align: center;padding-top: 10px" colspan="2">
                                <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" style="margin-left: 25%" size="small"
                                           @click="reset">重置</el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                v-loading="loading"
                                element-loading-text="拼命加载中"
                                element-loading-spinner="el-icon-loading"
                                element-loading-background="rgba(0, 0, 0, 0.1)"
                                :max-height="tableHeight"
                                style="width: 100%; height: 100%"
                                :border="true"
                                :row-style="{ height: '40px' }"
                                :header-cell-style="{
                background: '#EBEEF8',
                height: '50px',
                'text-align': 'center',
              }"
                                :cell-style="{ padding: '0', height: lineHeight }"
                        >
                            <el-table-column
                                    type="index"
                                    width="50"
                                    label="排序"
                                    align="center"
                            ></el-table-column>
                            <el-table-column
                                    prop="imei"
                                    label="设备Imei"
                                    align="center"
                            ></el-table-column>
                            <el-table-column
                                    prop="deviceType"
                                    label="设备类型"
                                    align="center"
                                    show-overflow-tooltip
                            >
                                <template slot-scope="scope">
                                    <span v-if="scope.row.deviceType == 1">塔机</span>
                                    <span v-if="scope.row.deviceType == 2">升降机</span>
                                    <span v-if="scope.row.deviceType == 3">单盲吊</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="companyName"
                                    label="公司名称"
                                    align="center"
                                    show-overflow-tooltip
                            ></el-table-column>
                            <el-table-column
                                    prop="contractNum"
                                    label="合同编号"
                                    align="center"
                            ></el-table-column>
                            <el-table-column
                                    prop="projectName"
                                    label="项目名称"
                                    align="center"
                                    show-overflow-tooltip
                            ></el-table-column>
                            <el-table-column
                                    prop="sceneNo"
                                    label="现场编号"
                                    align="center"
                                    show-overflow-tooltip
                            ></el-table-column>
                            <el-table-column
                                    prop="idcard"
                                    label="服务周期"
                                    align="center"
                                    show-overflow-tooltip
                            >
                                <template slot-scope="scope">
                                    <span v-if="scope.row.leaseCost <= 0">{{scope.row.serviceStartDate}} ~ {{scope.row.serviceEndDate}}</span>
                                    <span v-if="scope.row.leaseCost > 0">{{scope.row.leaseStartDate}} ~ {{scope.row.leaseEndDate}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="id" label="操作" align="center">
                                <template slot-scope="scope">
                                    <div class="opertion">
                    <i class="iconfont iconxiangqing"
                       title = '详情' style="color: #20a0ff"
                            @click="editMaterial(scope.row)"
                    ></i
                    >
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination
                            :pageSize="option.pageSize"
                            :pageTotal="totalPage"
                            :pageCurrent.sync="option.pageIndex"
                            :pageList="pageList"
                            @handlePageChange="pageChange"
                            @handleSizeChange="pageSizeChange"
                    />
                </div>
            </div>
            <el-dialog
                    :title="dialogName"
                    :visible.sync="dialogVisible"
                    width="40%"
                    :close-on-click-modal="false"
            >
                <el-table
                        :data="tableDetailData"
                        style="width: 100%"
                        :row-style="{ height: '40px' }"
                        height="400px"
                >
                    <el-table-column prop="operateType" label="操作"
                                     show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-if="scope.row.operateType == 1">新增设备</span>
                            <span v-if="scope.row.operateType == 2">修改设备</span>
                            <span v-if="scope.row.operateType == 3">终止设备</span>
                            <span v-if="scope.row.operateType == 4">停报设备</span>
                            <span v-if="scope.row.operateType == 5">恢复设备</span>
                            <span v-if="scope.row.operateType == 6">设备回款</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="operate" label="内容"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="createTime" label="时间"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="createUserName" label="人员"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="remarks" label="备注"
                                     show-overflow-tooltip></el-table-column>
                </el-table>

                <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                pageList: [15, 20, 30, 50],
                tableHeight: "",
                tableData: [],
                tableDetailData: [
                    {
                        id: "123",
                        operateType: "新增",
                        createTime: "张三",
                        createUserName: "110",
                        remarks: "21321312",
                    }
                ],
                option: {
                    pageSize: 15,
                    pageIndex: 1,
                },
                totalPage: 10,
                lineHeight: "",
                searchItem: {
                    imei: "",
                    contractNum: "",
                    companyName: "",
                    projectName: "",
                    userName: "",
                    deviceType: 0,
                },
                dialogVisible: false,
                dialogName: "",
                loading: false,
            };
        },
        methods: {
            editMaterial(val) {
                this.dialogVisible = true;
                this.dialogName = "操作记录"
                this.$api.getChargingDeviceOperateInfo({id: val.id}).then((res) => {
                    if (res.code == 200) {
                        this.tableDetailData = res.data;
                    }
                });
            },
            pageChange(option) {
                this.option.pageIndex = option;
                this.searchBtn();
            },
            pageSizeChange(val) {
                this.option.pageIndex = 1;
                this.option.pageSize = val;
                this.searchBtn();
            },
            searchBtn() {
                this.searchItem.pageIndex = this.option.pageIndex;
                this.searchItem.pageSize = this.option.pageSize;
                this.loading = true;
                this.$api.getChargingDevicesHistory(this.searchItem).then((res) => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                        this.loading = false;                        
                    }
                });
            },
            reset() {
                this.searchItem.imei = "";
                this.searchItem.contractNum = "";
                this.searchItem.companyName = "";
                this.searchItem.projectName = "";
                this.searchItem.userName = "";
                this.searchItem.deviceType = 0;
            },
        },
        created() {
            this.tableHeight = (window.innerHeight) * 0.76
            this.searchBtn();
        },
    };
</script>
<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    .iconbianji {
        margin-right: 10px;
        color: #20a0ff;
    }

    .iconshanchu {
        margin-left: 10px;
        color: red;
    }

    .page-body {
        background-color: #ffffff;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 100px;
        margin-top: 0.5%;
        background-color: #ffffff;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    td {
        text-align: right;
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    .dialogdiv {
        div {
            text-align: center;
            margin-top: 10px;
        }

        p {
            width: 80px;
            text-align: left;
            display: inline-block;
        }
    }

    .deviceCollection {
        height: 40px;
        width: 100%;
        padding: 0 5.7%;
        box-sizing: border-box;
        margin-top: 5%;

        div {
            // width: 25%;
            // float: left;
            display: flex;
            align-items: center;
            margin-top: 20px;
        }

        .el-input {
            width: 120px;
            margin-top: 0;
        }

        .deviceInput {
            margin: 0;
            padding: 0;
        }

        p {
            //margin-top: 20px;
            width: 80px;
        }
    }
</style>